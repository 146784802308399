@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", sans-serif;
  background-color: black;
  color: white;
}

.fp-watermark {
  display: none !important;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: white !important;
}

.heading {
font-weight: 700;
}
